import React, { Component } from 'react'

class Addon extends Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  // Tab
  openTabSection = (evt, tabNmae) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_item");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabNmae).style.display = "block";
    document.getElementById(tabNmae).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Add on Modules</span> of our</span>
              1inch Exchange Clone Script Software</h3>
          </div>
          <div className="row">
            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role="presentation">
                <li role='presentation' onKeyDown={this.openTabSection} className="current" onClick={(e) => this.openTabSection(e, 'tabs1')}>
                  Referral Program
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs2')} >
                  Multilingual support
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs3')} >
                  Forums
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs4')} >
                  Enhanced interface
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs5')} >
                  Support center
                </li>

              </ul>
              <div className="tab-content">
                <div id="tabs1" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Referral Program
                      </h4>
                      <p className="pharagraph">Our 1inch clone script can be integrated with a referral program in the Ethereum network to reward affiliates. The referral link can be generated by simply connecting the wallet.
                      </p>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="430px" height="287px" src="https://coinsclone.mo.cloudinary.net/images/1inch-new/referral-program.webp" alt="Referral Program" />
                    </div>
                  </div>
                </div>
                <div id="tabs2" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Multilingual support
                      </h4>
                      <p className="pharagraph">Our clone script comes with multilingual support where users can switch between languages of their choice and offer a better experience to the users.
                      </p>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="323px" height="272px" src="https://coinsclone.mo.cloudinary.net/images/1inch-new/multilingual-support.webp" alt="Multilingual support " />
                    </div>
                  </div>
                </div>
                <div id="tabs3" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Forums
                      </h4>
                      <p className="pharagraph">The forums pave the way to build a community and interact among the users with the healthy topic and our clone script provides an option to integrate it and access it easily by filtering the topics and tags.
                      </p>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="359px" height="290px" src="https://coinsclone.mo.cloudinary.net/images/1inch-new/forums.webp" alt="Forums " />
                    </div>
                  </div>
                </div>
                <div id="tabs4" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Enhanced interface</h4>
                      <p className="pharagraph">The user can customize the swapping interface by hiding/showing the transaction cost and changing themes such as day and night mode with available settings.
                      </p>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="585px" height="339px" src="https://coinsclone.mo.cloudinary.net/images/1inch-new/enhanced-interface.webp" alt="Enhanced interface" />
                    </div>
                  </div>
                </div>
                <div id="tabs5" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Support Center
                      </h4>
                      <p className="pharagraph">Admin and user interaction can be provided whereas the users can contact the admin directly from the support panel for issues that arise on the platform and troubleshoot the issues.
                      </p>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="386px" height="307px" src="https://coinsclone.mo.cloudinary.net/images/1inch-new/support-system.webp" alt="Support center " />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Addon