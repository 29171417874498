import { StaticImage } from 'gatsby-plugin-image'
import React,{useEffect,useState} from 'react'



const TechStack = () => {

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const updateMedia = () => {
      setIsDesktop(window.innerWidth > 768);
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", updateMedia);
    updateMedia(); // Check initial size

    return () => window.removeEventListener("resize", updateMedia);
  }, []);


  return (
    <section className="stack">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 mx-auto text-center">
            
            <h4 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Technologies Used</span> for</span> Our 1inch Exchange Clone Script</h4>
            <p className="pharagraph head">We use updated and cutting-edge technology tools for developing the 1inch exchange clone script website and mobile application which will be compatible with all major platforms like Android, IOS, and the web.
            </p>
            <div className="quick text-center">
              { isDesktop && (
            <StaticImage src="https://coinsclone.mo.cloudinary.net/images/home/technologies-stack.webp" 
              alt="Advanced Technology Stack in Our Cryptocurrency Exchange Script image1"
              width={1100} />
              )}
              { isMobile && (
            <StaticImage src="https://coinsclone.mo.cloudinary.net/images/workflow/techstack.webp" 
              alt="Advanced Technology Stack in Our Cryptocurrency Exchange Script image1"
              width={311} />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TechStack