import React, { Component } from 'react'


class CoreFeatures extends Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  // Tab
  openTabSection = (evt, tabName) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_items");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabName).style.display = "block";
    document.getElementById(tabName).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">

        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">

            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Core Features</span> of </span>
              Our 1inch Exchange Clone Script</h3>
          </div>
          <div className="row">

            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role='presentation'>
                <li className="current" role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab1')}>
                  Best Dex Agrregator
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab2')} >
                  Multi chain support
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab3')} >
                  Stake and Earn Rewards
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab4')} >
                  Reduced Slippage
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab5')} >
                  Supports a wide range of Digital wallets
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab6')} >
                  OTC Swap with Zero Slippage 
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab7')} >
                  Manage Staking Pools with Admin Console
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab8')} >
                  Admin Revenue Settings
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab9')} >
                  Swap history
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab10')} >
                  Analytics
                </li>
              </ul>

              <div className="tab-content">
                <div id="tab1" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Best Dex Agrregator
                        </h4>
                        <p className="pharagraph">Dex Aggregator combines the liquidity and the exchange rates of numerous Dex platforms and filters the best value for crypto traders. Our 1inch clone acts as the best Dex aggregator platform which can be scalable to access the wide range of liquidity sources with Ethereum, Binance, and polygon networks.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="358px" height="380px" src="https://coinsclone.mo.cloudinary.net/images/1inch-new/best-dex-agrregator.webp" alt="Best Dex Agrregator" />
                    </div>
                  </div>
                </div>
                <div id="tab2" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Multi chain support
                        </h4>
                        <p className="pharagraph">The 1inch clone software allows users to swap the leading network tokens such as Ethereum (ERC-20), Binance Smart Chain (BEP-20), Polygon, Optimism, Arbitrum, Avalanche, or Gnosis / xDAI.
                        </p> 
                        </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="270px" height="326px" src="https://coinsclone.mo.cloudinary.net/images/1inch-new/multi-chain-support.webp" alt="Multi chain support" />
                    </div>
                  </div>
                </div>
                <div id="tab3" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Stake and Earn Rewards
                        </h4>
                        <p className="pharagraph">Our 1inch clone script allows users to stake tokens available in the liquidity pool with the ratio of 1:1 with a specific locking period and earn LP tokens as a reward once the staking period is completed.
                        </p> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="283px" height="320px" src="https://coinsclone.mo.cloudinary.net/images/1inch-new/stake-and-earn-rewards.webp" alt="Stake and Earn Rewards" />
                    </div>
                  </div>
                </div>
                <div id="tab4" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Reduced Slippage
                        </h4>
                        <p className="pharagraph">With the wide range of connectivity in liquidity pools in different networks, the 1inch clone script helps in acquiring a lower slippage tolerance in comparison with other swap exchanges.
                        </p> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="337px" height="370px" src="https://coinsclone.mo.cloudinary.net/images/1inch-new/reduced-slippage.webp" alt="Reduced Slippage" />
                    </div>
                  </div>
                </div>
                <div id="tab5" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Supports a wide range of Digital wallets
                        </h4>
                        <p className="pharagraph">Our readymade 1inch clone software ensures scalability and feasible nature whereas it supports various kinds of Web3 wallets such as Metamask, Walletconnect, Coinbase wallet, Polygon, Arbitrum, and so on for the users to swap in a hassle-free manner.
                        </p> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img className="mw380" width="300px" height="393px" src="https://coinsclone.mo.cloudinary.net/images/1inch-new/supports-a-wide-range-of-digital-wallets.webp" alt="Supports a wide range of Digital wallets" />
                    </div>
                  </div>
                </div>
                <div id="tab6" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">OTC Swap with Zero Slippage
                        </h4>
                        <p className="pharagraph">Our clone script allows users to execute a trade through Private Market Makers. This feature allows users to trade with 0% slippage and users can receive an exact amount of tokens on the confirmation window.
                        </p> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="436px" height="206px" src="https://coinsclone.mo.cloudinary.net/images/1inch-new/otc-swap-with-zero-slippage.webp" alt="OTC Swap with Zero Slippage" />
                    </div>
                  </div>
                </div>
                <div id="tab7" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Manage Staking Pools with Admin Console
                        </h4>
                        <p className="pharagraph">An exclusive admin console will be provided with the 1inch clone script where the admin can track and manage users' transactions taking place in the platform in addition the admin can add or delete the staking pools and locking period accordingly.</p> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img className="mw315" width="447px" height="287px" src="https://coinsclone.mo.cloudinary.net/images/1inch-new/manage-staking-pools-with-admin-console.webp" alt="Manage Staking Pools with Admin Console" />
                    </div>
                  </div>
                </div>
                <div id="tab8" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Admin Revenue Settings
                        </h4>
                        <p className="pharagraph">The Admin console facilitates managing the admin revenue for the actions taking place in the platform such as swap, liquidity pool, and staking farms.
                        </p> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="363px" height="237px" src="https://coinsclone.mo.cloudinary.net/images/1inch-new/admin-revenue-settings.webp" alt="Admin Revenue Settings" />
                    </div>
                  </div>
                </div>
                <div id="tab9" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Swap history
                        </h4>
                        <p className="pharagraph">Our 1 inch clone script allows the users to look into their swap history which shows a wide range of information by just connecting their wallets.</p> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img className="mw380" width="400px" height="196px" src="https://coinsclone.mo.cloudinary.net/images/1inch-new/swap-history.webp" alt="Swap history" />
                    </div>
                  </div>
                </div>
                <div id="tab10" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Analytics
                        </h4>
                        <p className="pharagraph">The analytics module shares the statistics of the total liquidity, and total volume available in the platform and allows us to know the total rewards earned by the users with staking pools and referrals.</p> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="422px" height="254px" src="https://coinsclone.mo.cloudinary.net/images/1inch-new/analytics.webp" alt="Analytics" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CoreFeatures