import React from 'react'



class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis mb-0 py-0">
        <div className='gray-bg'>
      <div className="container">
        <div className="shadoww">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3 bluecolor">What is </span>
              White Label 1inch Exchange Clone Script?</h2>
            </div>
          </div>

          <div className="text-center">
            <p className="pharagraph" >1inch exchange clone script is the ready-to-launch DeFi exchange software that is trending in the crypto marketplace right now. This clone script comprises all the existing technical features and security protocols of the well-known DeFi exchange - 1inch exchange. Our premium 1inch exchange clone script is one of the rare scripts that help to build a DeFi exchange with smart routing and liquidity aggregation just like the 1inch platform. The workflow and other functions of this clone script are the same as the 1inch exchange.
            </p>
            <p className="pharagraph mb-0">In simple words, this script is a 100% replica of the 1inch exchange. The 1inch exchange clone software enables your users to swap tokens quickly and securely without any delay. This clone script is built using the most trustable blockchain network - Ethereum. So, users can swap all types of ERC20 tokens in a hassle-free manner. You can easily gain more yields by starting your own DeFi exchange using our 1inch exchange clone script. It supports a wide range of platform compliances with highly advanced usability and scalability.
            </p>
          </div>
        </div>
      </div>
      </div>
    </section>
    )
  }
}

export default Whatis