import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq PT-100">

      <Container>
        <div className="text-center">
          <h4 className="heading-h2"><span className="heading-h3 bluecolor d-none d-md-block">FAQ</span>
            Frequently Asked Questions</h4>
        </div>

        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">

                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" variant="link" eventKey="0">
                Can the 1inch Exchange clone script be customized?
                </Accordion.Toggle>
                 
              </div>

              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">It is completely a white-label DeFi exchange clone script. So once you have purchased it, you’re free to change its source code, appearance, add-ons, and other key functions in whichever way you feel like. We have a team of skilled developers who will implement the changes that you want. Therefore, we will completely customize the script according to your business goals.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle" variant="link" eventKey="1">
                Will I get support after I purchase the 1inch Exchange clone script from you?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">Our answer will be a big ‘YES’. We will offer you the effective customer support and technical assistance you need on a 24/7 basis.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle" variant="link" eventKey="2">
                How much does it cost to build a DeFi-based DEX aggregator platform like 1inch Exchange?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">Developing a DeFi exchange like a 1inch exchange from scratch is quite expensive. But if you purchase our 1inch Exchange clone script it comes at a budget-friendly cost. If you want us to customize and implement unique functionalities, then the development cost would vary. Therefore, we can give you an estimated cost of our 1inch clone script after the initial consultation.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle" variant="link" eventKey="3">
                What are the highlights of White label 1inch exchange clone software?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">Our clone script has many notable highlights. Such as faster deployment, highly responsive, comes with peculiar features, attractive UI/UX, multi-tested by first-class quality analysts, works on Ethereum blockchain, white label solution, high customization scope, and more. 
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card mb-0' : 'panel-wrap card mb-0'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle" variant="link" eventKey="4">
                How to create a DeFi Exchange similar to a 1inch Exchange?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">You can launch a fully functional DeFi exchange like a 1inch exchange within 7 days by using the premium ready-to-launch 1inch exchange clone script. You can get this clone script from the outstanding DeFi exchange clone script provider - Coinsclone. Our experts will gather your requirements, analyze your necessities, and provide ready-to-launch solutions.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion>
      </Container>

    </section>
  )
}

export default FaqSection