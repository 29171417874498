import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


class WhyShould extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    return (
      <section className="whyshould pt-100 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              
              <h2 className="heading-h2"><span className="heading-h3"><span className='bluecolor'>Reasons </span> To You Create A</span>
              DeFi Exchange Using 1inch Exchange Clone Script
              </h2>
              <p className="pharagraph head">
              </p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12  text-center" >
              <img width="610px" height="652px" src="https://coinsclone.mo.cloudinary.net/images/1inch-new/why-should-1-inch-clone.webp" alt="1inch exchange clone script software" />
            </div>
            <div className="col-lg-6 col-md-12">
              <ul>
                <li className="pharagraph" >Your users can begin token swapping instantly by merely linking the crypto wallet.
                </li>
                <li className="pharagraph" >Developed using the most secure blockchain network - Ethereum.
                </li>
                <li className="pharagraph" >You can launch a DeFi exchange in the minimum possible time.
                </li>
                <li className="pharagraph" >The cost-effective and modern solution to build a DeFi-based DEX aggregator platform.
                </li>
                <li className="pharagraph" >100% bug-free and highly responsive.
                </li>
                <li className="pharagraph" >1inch Exchange clone script supports multi-crypto wallet connection.</li>
                <li className="pharagraph" >No risk from hackers because this script is built with top-rated security features.
                </li>
                <li className="pharagraph" >Comes with versatile functionalities to attract new customers.
                </li>
                <li className="pharagraph" >High liquidity is available.
                </li>
                <li className="pharagraph" >Secured API integration is available.
                </li>
              </ul>

              <div className="banner-btn mt-4">
                <ButtonComponent />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyShould