import React from 'react'



const DevApproach = () => {


  return (
    <section className="devapproach pt-100 mb-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Development Approach</span> of</span>
            Our 1inch Exchange Clone Script </h3>
            <p className="pharagraph head">We at Coinsclone undergo the following procedures for 1inch Exchange clone script development.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12  text-center order1" >
          <img width="384px" height="264px" src="https://coinsclone.mo.cloudinary.net/images/paxful-new/requirment-and-planning.png" alt="Development and Approach" />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2">
            <h5>Requirement Gathering</h5>
            <p className="pharagraph" >Although you can use the script out of the box, our team can help you build an entire exchange application.
            </p>
            <br />
            <h5>Planning </h5>
            <p className="pharagraph" >Before we proceed, we will present you with a graphical and dynamic version of your platform to play around with.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12 padr40 order2">
            <h5>Designing</h5>
            <p className="pharagraph">Based on the analysis and the approval of the prototype, we will design and develop your NFT platform using our 1inch Exchange clone script. This includes coding and UI/UX design as per your preferences and branding needs.</p>
            <br />
            <h5>Development</h5>
            <p  className="pharagraph">After developing, designing, and testing, we will deploy the platform on the web. In case you have an app, we will also deploy the 1inch clone script-powered mobile app on Apple and Google Play Stores.
            </p>
          </div>
          <div className="col-lg-6 col-md-12  text-center order1">
          <img width="384px" height="264px" src="https://coinsclone.mo.cloudinary.net/images/paxful-new/design-development.png" alt="Designing and development" /></div>
        </div>
        <div className="row table-content orderflex mb-0">
          <div className="col-lg-6 col-md-12  text-center order2" >
          <img width="384px" height="264px" src="https://coinsclone.mo.cloudinary.net/images/paxful-new/testing-and-deployment.png" alt="Testing and deployment" />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order1">
            <h5>Testing</h5>
            <p  className="pharagraph">The trading platform powered by a 1inch Exchange clone script will be deployed and subjected to real-time testing.
            </p>
            <br />
            <h5>Deployment</h5>
            <p  className="pharagraph mb-0">After designing and developing, the platform will be subjected to rigorous testing and when all the kinks have been ironed out, we will deploy the platform on your web server, and it will be ready to use.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DevApproach