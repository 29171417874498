import React from 'react'


class TopFeatures extends React.Component {

  render() {
    return (
      <section className="topfeaturez pt-100 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Top Features</span> of </span>
                Our 1inch Exchange Clone Script</h2>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Trading Mechanism</h4>
                <p className="pharagraph">The 1inch clone script provides Limit Order Protocol facilitates the most innovative and flexible swap opportunities along with limit order in DeFi. The protocol’s features, such as dynamic pricing, and conditional orders, enable various order types.
                </p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
              <img  width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/1inch-new/trading-mechanism.webp" alt="Trading Mechanism" />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order1">
              <img  width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/1inch-new/liquidity-pools.webp" alt="Liquidity Pools" />
            </div>
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-right" >
                <h4 className="heading-h4">Liquidity Pools</h4>
                <p className="pharagraph">Our 1inch clone script enables users to add liquidity with the tokens they hold and it will be blocked on a smart contract. The liquidity providers earn a share of the transaction fee which is proportionate to their share of tokens in the pool.
                </p>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Place Limit Orders</h4>
                <p className="pharagraph">The users can place limited orders to buy or sell their desired tokens for their convenient price. Using our 1inch clone script users can even place an order without sufficient balance in their wallet. The limit order will be valid for a fixed time.</p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6  order1">
              <img  width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/1inch-new/place-limit-orders.webp" alt="Place Limit Orders" />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 right-side order1">
              <img  width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/1inch-new/decentralized-autonomous-organization.webp" alt="Decentralized Autonomous Organization (DAO)" />
            </div>
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-right" >
                <h4 className="heading-h4">Decentralized Autonomous Organization (DAO)</h4>
                <p className="pharagraph">The LP token stakers can vote for network governance with the 1 inch clone script. It is an open-source protocol where stakers can join the protocol whenever they need it. There are no barriers to joining and the stakers can vote for various protocol settings.
                </p>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex mb-0">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Integrated Trading charts</h4>
                <p className="pharagraph">Our clone script comes with trading charts to analyze the right time to enter or exit the market. These charts can give highs and lows for various time frames.
                </p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6  order1">
              <img  width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/1inch-new/integrated-trading-charts.webp" alt="Integrated Trading charts" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default TopFeatures